@media screen and (max-width: 1800px) {
    .container-fluid--md {
        max-width: 1280px;
    }

    .front__exhibition,
    .layer--exhibition {
        .block__title {
            font-size: 24px;
        }

        .block__btn {
            font-size: 16px;
            padding: 10px 12px;
        }
    }

    .front__layer {
        &--activities,
        &--news {
            .front__layer__content {
                padding-right: 60px;
            }

            .front__layer__img {
                padding-right: 0;
            }
        }

        &--activities {
            .front__layer__left {
                padding-right: 80px;
            }
        }

        &--news {
            .front__layer__left {
                width: calc(50% - 80px);
            }

            .front__layer__right {
                width: calc(50% + 80px);
            }
        }
    }

    .home {
        .banner__content {
            top: -20px;
        }
    }

    .banner__content {
        h1 {
            font-size: 37px;

            span {
                font-size: 65px;
            }
        }
    }
}

@media screen and (max-width: 1600px) {
    .header__bottom {
        > .container-fluid {
            > .row {
                > .col-12 {
                    &::before {
                        left: -320px;
                    }
                }
            }
        }
    }

    .front__layer__item {
        padding-right: 0;
    }
}

@media screen and (max-width: 1450px) {
    .front__exhibition {
        margin-top: -120px;
    }

    .activities__slider__arrow {
        &--prev {
            left: -10px;
        }

        &--next {
            right: -10px;
        }
    }

    .front__exhibition__inner {
        padding: 0 100px;
    }

    .home {
        .banner__content {
            top: 0;
        }
    }

    .block__title {
        font-size: 27px;
    }
}
