.marker__inner {
    display: block;
    padding: 8px 10px 8px 10px;
}

.marker__title {
    display: block;
    margin: 0 0 8px 0;
    font-size: 18px;
    font-weight: 700;
    color: $primarycolor;
    text-align: center;
}

.marker__item {
    display: block;
    margin-bottom: 5px;
    width: 100%;
    font-size: 15px;
    text-align: center;

    &.btn {
        margin: 15px 0 0 0;
    }
}

.opening__order {
    display: flex;
    flex-direction: column;

    &.reverse {
        flex-direction: column-reverse;
    }
}

.special__container {
    padding-top: 40px;
}

.contact__form__inner {
    padding: 55px 50px 125px 50px;
    background-color: #f9fafc;
    border-radius: $global-radius;

    h3 {
        font-size: 25px;
    }

    .gfield {
        input,
        select,
        textarea {
            background-color: #fff;
            border: 1px solid $bluecolor;

            &:focus {
                box-shadow: none;

                @extend .focus;
            }
        }

        textarea {
            max-height: 150px;
        }
    }
}

.btn--route {
    margin-bottom: 45px;
}

.contact__form__title {
    display: block;
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: 700;
}

.contact__form__text {
    display: block;
    margin-bottom: 25px;
}

.contact__company {
    font-size: $body-font-size;
    margin-bottom: 10px;
}

.contact__address {
    span {
        display: block;
    }
}

.contact__accessibility {
    list-style: none;
    margin: 0 0 20px;
    padding: 0;
    line-height: 31px;
    font-weight: 700;
}

.contact__icon {
    margin-right: 10px;

    svg {
        width: 16px;
        height: 16px;
        color: $primarycolor;
        fill: $primarycolor;
    }
}

.visit__block--contact {
    margin-top: 65px;

    .visit__block__title {
        font-weight: 700;
        font-size: 19px;
        display: block;
        margin-bottom: 5px;
    }

    .visit__block__item {
        margin-bottom: 10px;
    }
}

.visit__link {
    display: flex;
    align-items: center;
    font-weight: 700;

    &:hover {
        svg {
            left: 3px;
        }
    }

    svg {
        color: currentColor;
        position: relative;
        width: 10px;
        height: 34px;
        top: -1px;
        left: 0;
        margin-left: 10px;
        transition: left 0.2s ease-in-out;
    }
}

.contact__line {
    display: flex;
    line-height: 31px;
}

.contact__details {
    margin-bottom: 20px;
    font-size: 19px;
    font-weight: 700;
}

.contact__email {
    svg {
        color: $secondarycolor;
        transition: all 0.3s ease;
    }

    &:hover {
        svg {
            color: $body-font-color;
        }

        .contact__link {
            color: $body-font-color;
        }
    }

    .contact__link {
        color: $secondarycolor;
        transition: all 0.3s;
    }
}

.contact__line__col {
    width: 75px;
    font-weight: bold;
}

.contact__image {
    margin-top: 50px;
}

@media screen and (max-width: 1199px) {

}

@media screen and (max-width: 991px) {
    .contact__main {
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 767px) {

}

@media screen and (max-width: 575px) {

}
