@media screen and (max-width: 359px) {
    .banner__content h1 {
        font-size: 16px;
        margin-bottom: 130px;
    }

    .header__logo svg {
        width: 142px;
        height: 48px;
    }

    .banner__content .banner__title {
        font-size: 16px;
    }

    .nav-footer {
        li {
            a {
                margin-right: 20px;
            }
        }
    }

    .front__exhibition {
        margin-top: -210px;

        .block__item {
            &:hover {
                .block__content {
                    bottom: 20px;
                }
            }
        }
    }

    .front__exhibition__tag {
        left: -103px;
    }

    .archive__item--big {
        .block__title {
            font-size: 14px;
        }
    }

    .friends__item__inner {
        padding: 20px;
    }
}
