.about-us {
    background-color: rgba(9, 35, 118, 0.1);
    padding: 150px 0;
}

.about-us__left {
    text-align: center;
}

.about-us__row {
    align-items: center;
}

.about-us__right__inner {
    padding-left: 60px;

    p {
        margin-bottom: 40px;
    }
}

.about-us__subtitle {
    font-size: 21px;
    display: inline-block;
    margin-bottom: 8px;
}

.about-us__title {
    font-size: 42px;
}
