@mixin copyright_block_text_style {
    color: #e3e3f2;
    font-size: 14px;
    font-weight: 400;
    opacity: 0.5;
}

.footer {
    position: relative;
    z-index: 3;
    background-color: #e5e8f1;
}

.footer__row {
    padding: 120px 0;
}

.newsletter {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    max-width: 570px;
    margin-left: auto;
}

.newsletter__heading {
    display: flex;
    margin-bottom: 35px;
    width: 100%;
}

.newsletter__fields {
    flex: 1;

    .validation_error {
        flex: 0 0 100%;
        order: 4;
    }

    .validation_message {
        margin: 15px 0 10px;
    }

    .validation_message::after {
        margin: 0 0 0 10px;
    }

    .gfield_label {
        display: none;
    }

    .gfield input,
    .gfield select,
    .gfield textarea {
        background-color: #eff1f7;
        height: 59px;
        border: 1px solid $bluecolor;

        &:focus {
            box-shadow: none;
        }

        &::placeholder {
            color: #9aa5c7;
        }
    }

    .gform_button.btn {
        background-color: $primarycolor;
        height: 59px;

        &:hover,
        &:focus {
            background-color: darken($primarycolor, 5) !important;
        }
    }

    .gform_fields > li {
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0;
    }

    .gform_body {
        flex: 1;
    }

    .gform_ajax_spinner {
        position: absolute;
    }

    form {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;

        > .validation_error {
            display: none;
        }
    }

    input[type="email"] {
        opacity: 0.4;
        color: #092376;
        font-size: 19px;
        font-weight: 400;
        padding: 15px 25px;
        border: none;
        flex: 1;
    }

    input[type="email"]::placeholder {
        color: #092376;
        font-size: 19px;
        font-weight: 400;
    }
}

.newsletter__heading__text {
    padding-right: 30px;
    flex: 1;
}

.newsletter__title {
    font-size: 25px;
    margin-bottom: 5px;
}

.newsletter__subtitle {
    font-weight: 400;
}

.newsletter__heading__social {
    display: flex;
    flex-direction: row;
    padding: 0 0 0 30px;

    a {
        border-radius: 100%;
        background-color: $primarycolor;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        &.instagram {
            align-items: center;
            margin-left: 10px;
        }

        svg {
            height: 32px;
            color: #fff;
            fill: #fff;

            &.fa-instagram {
                height: 24px;
                width: 24px;
            }
        }

        &:hover {
            background-color: lighten($primarycolor, 5);
        }
    }
}

.footer__left {
    border-right: 4px solid rgba(9, 35, 118, 0.03);
}

.footer__left__inner {
    padding: 30px 0;

    svg {
        width: 310px;
        height: 85px;
    }
}

.copyright {
    background-color: $bluecolor;
    padding: 25px 0 20px 0;
}

.copyright__text {
    @include copyright_block_text_style;
}

.copyright__link {
    @include copyright_block_text_style;

    &:hover {
        opacity: 1;
        color: #e5e8f1;
    }

    &:focus {
        @extend .focus;

        outline-color: #fff;
    }
}

.copyright__row {
    align-items: center;
}

.copyright__text__spacer {
    margin: 0 10px;

    @include copyright_block_text_style;
}

.nav-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    li {
        a {
            margin-right: 30px;
            display: block;

            @include copyright_block_text_style;

            &:focus {
                @extend .focus;

                outline-color: #fff;
            }
        }

        &:last-of-type {
            a {
                margin-right: 0;
            }
        }
    }
}
