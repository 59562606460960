.friends-page__text {
    a:not(.btn--secondary) {
        color: $secondarycolor;
        text-decoration: underline;

        &:hover {
            color: $body-font-color;
            text-decoration: none;
        }
    }

    .btn--secondary {
        margin-top: 40px;
    }
}

.friends-page__img {
    img {
        border: 7px solid #e5e8f1;
    }
}

.usps {
    background-color: #f9fafc;
    padding: 75px 65px;
}

.usps_title {
    font-size: 25px;
    margin-bottom: 25px;
}

.usps__list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.usps__list__item {
    font-size: 19px;
    font-weight: 400;
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;

    svg {
        width: 20px;
        height: 15px;
        color: #6382ff;
        margin-top: 7px;
        margin-right: 10px;
    }
}

.friends {
    margin-top: 160px;
}

.friend__title {
    margin-bottom: 65px;
}

.friends__item {
    border: 1px solid #ebebeb;
    border-bottom: 0;
    border-left: 0;
}

.friends__item:nth-child(4n+1) {
    border-right: 0;
}

.friends__item:nth-child(-n+5) {
    border-top: 0;
}

.friends__item__inner {
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    img {
        transition: all 325ms ease-in-out;
    }

    a:hover {
        img {
            filter: grayscale(100%);
        }
    }
}

@media screen and (max-width: 1199px) {
    .friends__item:nth-child(-n+5) {
        border-top: 0;
    }
}

@media screen and (max-width: 991px) {
    .friends__item {
        width: 25%;
        flex: 0 0 25%;
    }
}

@media screen and (max-width: 767px) {
    .friends__item {
        width: 50%;
        flex: 0 0 50%;
        border-top: 1px solid #ebebeb;

        &:nth-child(-n+4) {
            border-top: 1px solid #ebebeb;
        }

        &:nth-child(-n+5) {
            border-top: 1px solid #ebebeb;
        }

        &:nth-child(2n+1) {
            border-right: 0;
        }
    }
}
