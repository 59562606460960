body {
    font-family: $font-primary;
    font-size: $body-font-size;
    font-weight: $body-font-weight;
    line-height: $body-line-height;
    color: $body-font-color;

    &.front-page-data {
        .main {
            background-color: transparent;
        }

        .about-us {
            background-color: #e6e9f1;
        }

        .visit {
            background-color: #fff;
        }
    }
}

.main {
    position: relative;
    z-index: 3;
    background-color: #fff;
    padding: 0;
}

.layer {
    padding: 95px 0;

    &--exhibition {
        padding-top: 0;
    }

    &--openinghours {
        padding-bottom: 0;
    }

    &.single__content {
        padding-bottom: 80px;
    }

    &.single__content--bottom-padding {
        padding-bottom: 95px;
    }
}

.single-activiteiten {
    .visit {
        padding-top: 110px;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $header-font-family;
    color: $header-color;
    font-weight: $header-font-weight;
}

h1,
h2 {
    margin-bottom: 30px;
}

h3 {
    margin-bottom: 20px;
}

h4 {
    margin-bottom: 0;
}

h5,
h6 {
    margin-bottom: 15px;
}

.focus {
    outline-style: dotted;
    outline-width: 0.2rem;
    outline-color: $primarycolor;
    outline-offset: 5px;
    transition: none;
    box-shadow: none;
}

a {
    color: $secondarycolor;
    transition: all 300ms ease-in-out;

    &:focus {
        @extend .focus;
    }

    &:hover {
        text-decoration: none;
        color: darken($secondarycolor, 3);
    }
}

.acf-map {
    height: 500px;
    margin: 0;
    width: 100%;
}

.acf-map img {
    max-width: inherit !important;
}

.archive__list {
    margin-top: 40px;
}

.slick-slider {
    transition: all 0.3s ease;
}

.slick-slide {
    outline: none;

    &:hover {
        outline: none;
    }
}

.svg-inline--fa {
    width: 20px;
    height: auto;
}

.slick-dots {
    bottom: -80px;

    li {
        height: auto;
        width: auto;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        button {
            height: auto;
            width: auto;
            padding: 0 17px;
            margin: 0;

            &::before {
                content: '';
                position: absolute;
                top: 3px;
                left: 3px;
                background-color: rgba(255, 255, 255, 0.3);
                height: 10px;
                width: 10px;
                border: 3px solid transparent;
                border-radius: 50%;
                box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
                opacity: 1;
                transition: all 0.3s ease;
            }

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 16px;
                width: 16px;
                border-radius: 50%;
                background-color: transparent;
                border: 3px solid transparent;
                transition: all 0.3s ease;
            }
        }

        &.slick-active {
            button {
                &::before {
                    background-color: transparent;
                }

                &::after {
                    border-color: #fff;
                    box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
                }
            }
        }
    }
}

.template-tentoonstellingen-data,
.template-educatie-data,
.template-activiteiten-data,
.template-arrangementen-data {
    .block__item {
        position: relative;
        display: block;

        &:focus {
            &::after {
                content: '';
                position: absolute;
                top: -10px;
                left: -10px;
                width: calc(100% + 20px);
                height: calc(100% + 20px);
                border: 0.2rem dotted $primarycolor;
            }
        }
    }
}

.block__item {
    &:focus {
        @extend .focus;

        outline-color: #fff;
        outline-offset: -4px;
    }

    &:hover {
        .block__content {
            bottom: 40px;
        }

        .block__btn {
            visibility: visible;
            opacity: 1;
        }
    }
}

.block__img {
    position: relative;
    display: block;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.16);
    overflow: hidden;

    img {
        width: 100%;
        transition: all 0.3s ease;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
    }
}

.block__content {
    position: absolute;
    z-index: 2;
    bottom: -44px;
    left: 0;
    width: 100%;
    color: #fff;
    text-align: center;
    padding: 0 30px;
    transition: all 0.3s ease;
}

.block__tag {
    display: block;
    font-size: 21px;
    font-weight: 400;
}

.block__title {
    display: block;
    font-size: 34px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 20px;
}

.block__btn {
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease;

    span {
        margin-left: 5px;
    }
}

.popup__content {
    max-width: 767px;
    margin-left: 20px;
    margin-right: 20px;
}

@media screen and (max-width: 1199px) {

}

@media screen and (max-width: 991px) {

}

@media screen and (max-width: 767px) {
    body {
        .header__logo {
            opacity: 1;
            transition: opacity 325ms ease-in-out;
        }

        &.menu__open {
            .header__logo {
                opacity: 0;
            }
        }
    }

    .acf-map {
        height: 350px;
    }

    .popup__content {
        padding: 40px 25px 25px;
    }
}

@media screen and (max-width: 575px) {

}
