.visit {
    padding: 130px 0 170px;
}

.visit__title {
    font-size: 42px;
    margin-bottom: 50px;
}

.row--visit {
    margin-left: 0;
    margin-right: 0;

    > .col-sm-12 {
        padding-left: 0;
        padding-right: 0;
    }
}

.visit__block {
    &:not(:last-child) {
        border-right: 4px solid rgba(#092376, 0.06);
    }

    .btn {
        padding: 14px 25px;
        height: auto;
    }
}

.visit__block__inner {
    background-color: #f9fafc;
    padding: 65px 60px;
}

.visit__block__title {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 15px;
}

.visit__block__content {
    margin-bottom: 40px;
}

.visit__block__item {
    font-size: 19px;
    display: block;
}

.visit__block__item--bold {
    font-weight: 700;
}

.visit__block__item--p {
    margin-bottom: 0;
}
