.btn {
    position: relative;
    padding: 14px 30px;
    border: 0;
    margin-right: 8px;
    color: #fff;
    font-size: 21px;
    border-radius: 0;
    font-weight: 600;
    display: inline-flex;
    align-items: center;

    &:hover,
    &:active {
        box-shadow: none !important;
        color: #fff;
    }

    &:focus {
        @extend .focus;
    }

    &:last-child {
        margin-right: 0;
    }
}

.btn--primary {
    background-color: $primarycolor;

    &:hover,
    &:active {
        background-color: lighten($primarycolor, 5) !important;
    }

    &:focus {
        outline-color: $primarycolor;
    }
}

.btn--secondary {
    background-color: $secondarycolor;

    &:hover,
    &:active {
        background-color: lighten($secondarycolor, 5) !important;
    }

    &:focus {
        outline-color: $secondarycolor;
    }
}

.btn--white {
    background-color: #fff;
    color: $body-font-color;

    &:hover,
    &:active {
        color: $primarycolor;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.45) !important;
    }

    &:focus {
        outline-color: $primarycolor;
    }
}

.btn--next {
    background-color: $primarycolor;
    padding-right: 38px;

    &::after {
        content: '';
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: -9px;
        width: 7px;
        height: 18px;
        background-image: url('../../svg/angle-right-regular-white.svg');
        background-size: 7px 18px;
    }

    &:hover,
    &:active {
        background-color: lighten($primarycolor, 5) !important;
    }

    &:focus {
        outline-color: $primarycolor;
    }
}

.btn--route {
    background-color: #29497a;
    padding: 14px 20px;
}

.btn--prev {
    background-color: $primarycolor;
    padding-left: 38px;

    &::before {
        content: '';
        position: absolute;
        left: 15px;
        top: 50%;
        margin-top: -9px;
        width: 7px;
        height: 18px;
        background-image: url('../../svg/angle-left-regular-white.svg');
        background-size: 7px 18px;
    }

    &:hover,
    &:active {
        background-color: lighten($primarycolor, 5) !important;
    }

    &:focus {
        outline-color: $primarycolor;
    }
}
