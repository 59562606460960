@import "~bootstrap/scss/bootstrap";

@media (min-width: 1200px) {
    .container {
        max-width: 1640px;
    }

    .container-fluid {
        max-width: 1640px;
    }

    .container-fluid--small {
        max-width: 1280px;
    }
}
