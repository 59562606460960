.faq-container {
    margin-top: 30px;
}

.faq__title {
    font-size: 1.5rem;
}

.accordion {
    margin-bottom: 30px;

    .card {
        background: #f9fafc;
        color: $body-font-color;
        margin-bottom: 15px;
        border-radius: 0;
        border: none;

        &:last-of-type,
        &:not(:first-of-type):not(:last-of-type),
        &:first-child {
            border-radius: 0;
        }
    }
}

.card-header {
    border-bottom: none;
    font-weight: 600;
    background: #f0f0f0;
    padding: 0;
    font-size: 18px;

    a {
        color: $body-font-color;
        background: #f9fafc;
        padding: 20px 20px 0 27px;
        display: block;
        transition: all 0.3s;
        font-weight: 700;

        &.collapsed {
            padding-bottom: 20px;
        }

        &::before {
            content: "";
            float: right;
            width: 15px;
            height: 15px;
            background: url(../../svg/angle-up-solid-blue.svg) no-repeat center;
            background-size: 10px 15px;
            margin: 5px 3px 5px 15px;
            transition: all 0.3s;
        }

        &.collapsed::before {
            transform: rotate(-180deg);
        }
    }
}

.card-body {
    padding: 22px 70px 22px 27px;
    font-size: 18px;
}

@media screen and (max-width: 1199px) {

}

@media screen and (max-width: 991px) {

}

@media screen and (max-width: 767px) {
    .faq__title {
        font-size: 18px;
    }

    .faq-container {
        margin-top: 10px;
    }

    .card-header {
        font-size: inherit;
    }

    .card-header a {
        padding-left: 20px;
    }

    .card-body {
        padding: 15px  20px;
        font-size: inherit;
    }

    .accordion {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 575px) {

}
