.openinghours__block {
    display: block;
    margin-top: 40px;

    &--special {
        margin: 0 0 40px !important;

        .season__period {
            margin-bottom: 10px;
        }
    }
}

.season__title {
    display: block;
    margin-bottom: 10px;
    font-size: 21px;
    font-weight: 300;
    font-style: italic;
    color: $bluecolor;
    line-height: 0.5;
}

.season__period {
    display: block;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 700;
    color: $primarycolor;
}

.opening__list {
    display: block;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        display: flex;
        width: 100%;
        padding: 3px 0;
    }

    &--special {
        .opening__left {
            flex: 1;
            width: auto;
            padding-right: 20px;
        }

        .opening__right {
            flex: inherit;
            text-align: left;
            min-width: 85px;
        }
    }
}

.opening__left {
    display: block;
    float: left;
    width: 130px;
}

.opening__right {
    flex: 1;

    &--closed {
        color: $secondarycolor;
    }
}
