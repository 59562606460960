@media screen and (max-width: 1199px) {
    @media (orientation: landscape) {
        .front__exhibition {
            margin-top: -175px;
        }

        .front__exhibition__tag {
            font-size: 19px;
        }
    }

    .menu__open .header__bottom__btns {
        padding-right: 0;
    }

    .archive__item {
        &--big {
            .block__title {
                font-size: 24px;
            }

            .block__tag {
                font-size: 20px;
            }
        }
    }

    .banner__content h1 {
        font-size: 37px;
    }

    .banner__content h1 span {
        font-size: 65px;
    }

    .banner__content {
        bottom: 15px;
    }

    .banner__content .banner__title {
        font-size: 22px;
    }

    .banner__content .banner__title span {
        font-size: 32px;
    }

    .front__layer__left {
        width: 45%;

        .front__layer__content {
            padding-right: 60px;
        }
    }

    .front__layer__right {
        width: 55%;

        .front__layer__content {
            padding-left: 60px;
            padding-right: 15px;
        }
    }

    .footer__left__inner {
        svg {
            width: 250px;
            height: 68px;
        }
    }

    .about-us {
        padding: 80px 0 60px;
    }

    .visit {
        padding: 80px 0 120px;
    }

    .visit__block__title {
        font-size: 20px;
    }

    body {
        &.menu__open {
            padding-right: 0;
        }
    }

    .iframe__wrapper {
        iframe {
            min-height: 790px !important;
        }
    }
}
