.archive__content__geschiedenis {
    text-align: center;
    padding-bottom: 140px;

    h1 {
        margin-bottom: 20px;
    }

    p {
        max-width: 650px;
        margin: 0 auto;
    }
}

.archive__geschiedenis {
    padding-top: 0;
    position: relative;
    padding-bottom: 60px;

    &::after {
        content: "";
        position: absolute;
        top: 10px;
        left: 50%;
        margin-left: -3px;
        width: 7px;
        height: calc(100% - 10px);
        background-color: #e5e8f1;
    }
}

.geschiedenis__content {
    max-width: 400px;
    margin-left: 20px;
}

.geschiedenis__image {
    display: inline-block;
    z-index: 1;
    position: relative;
    transform: translateX(-90%);
    margin-top: 65px;
    margin-bottom: 85px;
    max-width: 700px;

    a {
        position: relative;
        display: block;
    }

    img {
        border: 7px solid #e5e8f1;
        overflow: hidden;
        max-height: 500px;
    }
}

.geschiedenis__item {
    &:nth-child(even) {
        .geschiedenis__image {
            transform: translateX(-10%);
        }
    }
}

.geschiedenis__date {
    color: $primarycolor;
    font-weight: bold;
    font-size: 44px;
    position: relative;
    display: inline-block;
    line-height: 1.2;

    &::before {
        content: "";
        position: absolute;
        width: 22px;
        height: 22px;
        border-radius: 100%;
        border: 5px solid #ff4b55;
        background-color: #d9000d;
        top: 8px;
        left: -50px;
        z-index: 5;
    }
}

@media screen and (max-width: 1199px) {
    .geschiedenis__content {
        max-width: 100%;
    }
}

@media screen and (max-width: 991px) {
    .archive__geschiedenis::after {
        left: 25px;
    }

    .geschiedenis__date::before {
        left: -45px;
    }

    .geschiedenis__content {
        margin-left: 40px;
    }

    .geschiedenis__image {
        transform: translateX(0);
        margin-left: 40px;
        margin-bottom: 40px;
        margin-top: 20px;
    }

    .geschiedenis__image:nth-child(even) {
        transform: translateX(0);
        margin-left: 40px;
        margin-bottom: 40px;
        margin-top: 20px;
    }

    .geschiedenis__item:nth-child(even) .geschiedenis__image {
        transform: translateX(0);
        margin-left: 40px;
        margin-bottom: 40px;
        margin-top: 20px;
    }
}

@media screen and (max-width: 767px) {
    .geschiedenis__date {
        font-size: 31px;
        line-height: 1.6;
    }

    .archive__geschiedenis {
        padding-bottom: 10px;
    }
}

@media screen and (max-width: 575px) {
    .geschiedenis__content {
        margin-left: 30px;
    }

    .geschiedenis__image,
    .geschiedenis__image:nth-child(2n),
    .geschiedenis__item:nth-child(2n) .geschiedenis__image {
        transform: translateX(0);
    }

    .geschiedenis__date::before {
        left: -35px;
    }
}
