/** Import Bootstrap functions */
@import "~bootstrap/scss/functions";

$slick-loader-path: "~slick-carousel/slick/";
$slick-font-path: "~slick-carousel/slick/fonts/";

$primarycolor: #092376;
$secondarycolor: #d9121e;
$bluecolor: #29497a;

$font-primary: 'Josefin Sans', sans-serif;
$font-secondary: '';
$body-font-size: 19px;
$body-font-weight: 400;
$body-line-height: 1.6;
$body-font-color: #092376;

$header-font-family: 'Josefin Sans', sans-serif;
$header-color: $body-font-color;
$header-font-weight: bold;

$global-radius: 0;

$theme-colors: (
    primary: $primarycolor
);

/** Bootstrap navbar fix (https://git.io/fADqW) */
$navbar-dark-toggler-icon-bg: none;
$navbar-light-toggler-icon-bg: none;

//Bootstrap
$grid-gutter-width: 40px !default;

$enable-responsive-font-sizes: true;
$h1-font-size: 44px;
$h2-font-size: 36px;
$h3-font-size: 28px;
$h4-font-size: 20px;
$h5-font-size: 20px;
