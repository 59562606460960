.single__sidebar {
    padding-left: 50px;

    > div {
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }
}

.sidebar__contact {
    display: block;
    padding: 30px 39px 34px;
    background-color: #f3f3f3;
    border-radius: $global-radius;

    .btn {
        display: block;
        margin-right: 0;
        text-align: left;

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}

.sidebar__title {
    display: block;
    margin-bottom: 20px;
    font-size: 23px;
    font-weight: 700;
    color: $primarycolor;
}

.sidebar__contact__text {
    display: block;
    margin-bottom: 20px;
}

.sidebar__nieuws {
    display: block;
    padding: 30px 39px 34px;
    background-color: #f3f3f3;
    border-radius: $global-radius;
}

.sidebar__nieuws__item {
    position: relative;
    display: block;
    padding: 7px 18px;
    border-radius: $global-radius;
    margin-right: 0;
    color: $body-font-color;
    text-align: left;
    background-color: #e4e4e4;

    &:not(:last-child) {
        margin-bottom: 10px;
    }

    &:hover {
        color: $body-font-color;
        background-color: darken(#e4e4e4, 3%);

        .sidebar__nieuws__arrow {
            right: 13px;
        }
    }
}

.sidebar__nieuws__title {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 18px;
}

.sidebar__nieuws__arrow {
    position: absolute;
    top: 50%;
    margin-top: -2px;
    right: 15px;
    transform: translateY(-50%);
    transition: all 0.3s ease;

    svg {
        width: 8px;
        height: 18px;
        color: $body-font-color;
        fill: $body-font-color;
    }
}

.sidebar__page {
    background-color: #f9fafc;
    padding: 50px;

    .btn {
        margin-top: 20px;
    }
}

.list__pages {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        margin-bottom: 10px;

        a {
            position: relative;
            display: block;
            padding-left: 24px;
            color: #092376;
            font-size: 19px;

            &:hover {
                svg {
                    left: 3px;
                }
            }

            svg {
                position: absolute;
                top: 2px;
                left: 0;
                height: 18px;
                width: 10px;
                color: #6382ff;
                fill: #6382ff;
                transition: all 0.3s ease;
            }
        }
    }
}

.sidebar__img {
    display: block;
    margin-bottom: 40px;

    img {
        width: 100%;
    }
}

.sidebar__education__row {
    display: flex;
    flex-direction: row;

    &:not(:last-child) {
        margin-bottom: 8px;
    }
}

.sidebar__education__left {
    width: 100px;
    font-weight: 700;
}

.sidebar__education__right {
    display: flex;
    flex-direction: column;
    flex: 1;
}

@media screen and (max-width: 1199px) {
    .single__sidebar {
        padding-left: 20px;
    }
}

@media screen and (max-width: 991px) {
    .single__sidebar {
        margin-top: 40px;
        max-width: 500px;
    }
}

@media screen and (max-width: 767px) {
    .sidebar__page {
        padding: 25px 30px;
    }

    .list__pages {
        li {
            a {
                padding-left: 18px;

                svg {
                    height: 14px;
                    width: 8px;
                }
            }
        }
    }
}

@media screen and (max-width: 575px) {

}
