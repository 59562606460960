@media screen and (max-width: 991px) {
    .container-fluid--arrangements {
        padding-left: 20px;
        padding-right: 20px;
    }

    .nav-primary .nav > li.open > a {
        margin: 0;
    }

    .block__btn {
        span {
            display: none;
        }
    }

    .banner__content h1 {
        font-size: 30px;
    }

    .banner__content h1 span {
        font-size: 55px;
    }

    .nav-primary .nav > li > a:focus {
        outline: none;
    }

    .row--archive {
        margin-left: -20px;
        margin-right: -20px;

        > .col-6 {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .archive__item {
        margin-bottom: 40px;

        &--big {
            .block__content {
                padding: 0 20px;
            }

            .block__title {
                font-size: 18px;
            }

            .block__tag {
                font-size: 16px;
            }
        }
    }

    .front__exhibition {
        .block__tag {
            font-size: 16px;
        }

        .block__title {
            font-size: 18px;
        }

        .block__btn {
            font-size: 15px;
            padding: 8px 10px;
        }
    }

    .front__layer {
        padding: 80px 0;

        &--activities {
            .front__layer__left {
                width: 100%;
                padding-right: 0;
            }

            .front__layer__right {
                width: 100%;
            }
        }

        &--news {
            .front__layer__left {
                order: 1;
                width: 100%;
            }

            .front__layer__right {
                order: 0;
                width: 100%;
            }
        }
    }

    .front__activities__container {
        max-width: 600px;
        margin: 0 auto;
    }

    .front__layer__item {
        flex-direction: column;
        max-width: 600px;
        margin: 0 auto;

        .front__layer__left {
            width: 100%;

            .front__layer__content {
                padding-right: 0;
            }
        }
    }

    .front__layer__img {
        margin-bottom: 40px;
    }

    .slick-dots {
        bottom: -60px;
    }

    .nav-footer {
        justify-content: flex-start;
    }

    .copyright {
        padding: 35px 0 30px;
    }

    .copyright__left {
        margin-bottom: 10px;
    }

    .footer__row {
        padding: 80px 0;
    }

    .footer__left {
        border-right: none;
    }

    .footer__left__inner {
        border-bottom: 5px solid rgba(9, 35, 118, 0.03);
    }

    .footer__right__inner {
        padding-top: 30px;
    }

    .newsletter {
        margin: 0;
        max-width: initial;
    }

    .newsletter__heading {
        flex-wrap: wrap;
    }

    .newsletter__heading__text {
        flex: 0 0 100%;
    }

    .newsletter__heading__social {
        padding: 20px 0 0 0;
    }

    .newsletter__fields {
        width: 100%;

        input[type="email"] {
            width: 100%;
        }

        input[type="submit"] {
            font-size: 18px;
        }
    }

    .layer.single__content {
        padding-bottom: 80px;
    }

    .visit {
        padding: 80px 0;
    }

    .single-activiteiten {
        .visit {
            padding-top: 0;
        }
    }

    .visit__block {
        background-color: transparent;
        border-right: 0;
        padding: 0;

        &:not(:last-child) {
            flex: 0 0 50%;
            max-width: 50%;
            border-right: 0;
            border-bottom: 4px solid rgba(#092376, 0.06);
        }

        &:nth-child(-n+1) {
            border-right: 4px solid rgba(#092376, 0.06);
        }
    }

    .visit__block__inner {
        padding: 40px;
    }

    .visit__title {
        margin-bottom: 30px;
    }

    .about-us__right__inner {
        padding-left: 0;
    }

    .about-us {
        padding: 80px 0;
    }

    .about-us__left {
        text-align: left;
        margin-bottom: 40px;

        img {
            max-width: 275px;
        }
    }

    .about-us__title {
        font-size: 35px;
    }

    .friends-page__text {
        margin-bottom: 30px;

        .btn--secondary {
            margin-top: 10px;
        }
    }

    .friends-page__img {
        margin-bottom: 30px;
    }

    .usps {
        padding: 45px 35px;
        max-width: 400px;
    }

    .usps__list__item {
        display: flex;
    }

    .friends {
        margin-top: 90px;
    }

    .friend__title {
        margin-bottom: 30px;
        font-size: 20px;
    }

    .sidebar__page {
        background-color: #f9fafc;
        padding: 40px;
    }

    .special__container {
        margin-top: 60px;
    }

    .archive__content__geschiedenis {
        padding-bottom: 80px;
        text-align: start;

        p {
            max-width: 100%;
            margin: unset;
        }
    }

    .archive__content--kunstwerken {
        padding-bottom: 70px;
    }

    .kunstwerk__slide__summary {
        margin-top: 15px;
    }

    .iframe__wrapper {
        iframe {
            min-height: 900px !important;
        }
    }

    @media (orientation: landscape) {
        .front__exhibition {
            margin-top: -75px;
        }

        .banner__content h1 {
            font-size: 20px;
            margin-bottom: -30px;

            span {
                font-size: 45px;
            }
        }
    }
}
