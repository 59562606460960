.banner {
    position: relative;

    iframe {
        display: table;
    }

    video {
        width: 100%;
        height: auto;
    }

    img {
        width: 100%;
    }

    &--home {
        .banner__slider {
            height: calc(100vh - 40px);
            width: 100%;

            .slick-list {
                height: 100%;
                width: 100%;
            }

            .slick-track {
                height: 100%;
            }
        }

        .banner__slide {
            position: absolute;
            height: 100%;
            width: 100%;
            background-size: cover;
            background-position: center center;
        }
    }
}

.banner__content {
    position: absolute;
    left: 0;
    bottom: 88px;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    color: #fff;
    z-index: 1;
    pointer-events: none;

    h1 {
        color: #fff;
        font-size: 44px;
        text-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
        font-weight: 400;
        margin-bottom: 0;

        span {
            font-size: 75px;
            display: block;
            font-weight: 700;
        }
    }

    .banner__title {
        font-size: 26px;
        color: #fff;
        font-weight: 400;
        line-height: 1.3;

        span {
            font-size: 44px;
            display: block;
            font-weight: 700;
        }
    }
}

.home {
    .banner__content {
        bottom: auto;
        top: 0;
        height: 100%;
        display: flex;
    }
}
