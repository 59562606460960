.archive__content--kunstwerken {
    background-color: #f5f5f5;
}

.layer--kunstwerken-overzicht {
    padding: 200px 0 40px;
}

.archive__kunstwerken {
    overflow: hidden;
    position: relative;
}

.kunstwerken__intro {
    margin-bottom: 70px;
}

.kunstwerk__slide__inner {
    display: flex;
    color: $primarycolor;
    align-items: flex-start;
    padding: 15px 0;

    &:focus {
        @extend .focus;
    }

    &:hover {
        color: $primarycolor;

        .kunstwerk__slide__readmore {
            color: lighten($secondarycolor, 15);
            text-decoration: none;
        }
    }
}

.sf-field-category ul li input[type=checkbox] {
    &:focus {
        @extend .focus;
    }
}

.kunstwerk__slide {
    margin: 0 90px;

    &:focus {
        outline: none;
        transition: none;
        box-shadow: none;

        .kunstwerk__slide__content {
            @extend .focus;
        }
    }
}

.kunstwerk__slide__content {
    flex: 0 0 40%;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.08);
    background-color: #fff;
    text-align: start;
    padding: 50px;
    max-width: 430px;
    margin-right: 30px;
}

.kunstwerk__slide__title {
    font-size: 22px;
    margin-bottom: 2px;
}

.archive__kunstwerken__arrow {
    position: absolute;
    z-index: 3;
    top: calc(50% - 15px);
    width: 54px;
    cursor: pointer;
    height: 54px;
    background-color: #e5e8f1;
    display: flex !important;
    justify-content: center;
    align-items: center;
    transition: all 325ms ease-in-out;

    svg {
        width: 15px;
        height: 22px;
        color: $primarycolor;
        fill: $primarycolor;
        transition: all 325ms ease-in-out;
    }

    &:hover {
        background-color: $primarycolor;

        svg {
            color: #fff;
            fill: #fff;
        }
    }

    &--prev {
        left: 230px;

        &--responsive {
            position: static;
        }
    }

    &--next {
        right: 230px;

        &--responsive {
            position: static;
        }
    }
}

.kunstwerken__slider {
    .slick-prev,
    .slick-next {
        width: 54px;
        height: 54px;
        background-color: #e5e8f1;
    }

    .slick-prev {
        left: 230px;
    }

    .slick-next {
        right: 230px;
    }
}

.slider-nav-responsive {
    display: none;
    justify-content: space-between;
}

.prev-wrap,
.next-wrap {
    display: flex;
    align-items: center;
}

.prev-wrap {
    padding-left: 20px;
}

.next-wrap {
    padding-right: 20px;
}

.prev-text {
    padding-left: 13px;
}

.next-text {
    padding-right: 13px;
}

.kunstwerk__slide__readmore {
    color: $secondarycolor;
    text-decoration: underline;
    display: block;
    margin-top: 30px;
}

.kunstwerk__slide__subtitle {
    font-size: 22px;
    font-weight: 300;
    font-style: italic;
}

.kunstwerk__slide__summary {
    margin-top: 50px;
    display: block;
}

.kunstwerk__slide__img__wrapper {
    flex: 0 0 60%;
    margin-left: 30px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.23);
    display: block;

    img {
        width: 100%;
    }
}

.kunstwerken__btns {
    margin-bottom: 40px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .archive__kunstwerken__arrow--next {
        right: 50px;
    }

    .archive__kunstwerken__arrow--prev {
        left: 50px;
    }

    .kunstwerk__slide__img__wrapper {
        flex: 0 0 47%;
    }

    .kunstwerk__slide__content {
        flex: 0 0 50%;
    }

    .kunstwerk__slide {
        margin: 0 500px;
    }
}

@media screen and (max-width: 1803px) {
    .archive__kunstwerken__arrow--next {
        right: 165px;
    }

    .archive__kunstwerken__arrow--prev {
        left: 165px;
    }
}

@media screen and (max-width: 1680px) {
    .archive__kunstwerken__arrow--next {
        right: 95px;
    }

    .archive__kunstwerken__arrow--prev {
        left: 95px;
    }
}

@media screen and (max-width: 1600px) {
    .archive__kunstwerken__arrow--next {
        right: 20px;
    }

    .archive__kunstwerken__arrow--prev {
        left: 20px;
    }
}

@media screen and (max-width: 1450px) {
    .kunstwerk__slide {
        margin: 0 110px;
    }
}

@media screen and (max-width: 1380px) {
    .kunstwerk__slide__inner {
        flex-direction: column-reverse;
    }

    .kunstwerk__slide__img__wrapper {
        margin-left: 0;
    }

    .kunstwerk__slide__content {
        max-width: 748px;
        margin-right: 0;
    }

    .archive__kunstwerken__arrow--next {
        right: 180px;
    }

    .archive__kunstwerken__arrow--prev {
        left: 180px;
    }
}

@media screen and (max-width: 1215px) {
    .archive__kunstwerken__arrow--next {
        right: 80px;
    }

    .archive__kunstwerken__arrow--prev {
        left: 80px;
    }
}

@media screen and (max-width: 1199px) {
    .archive__kunstwerken__arrow--next {
        right: 25px;
    }

    .archive__kunstwerken__arrow--prev {
        left: 25px;
    }
}

@media screen and (max-width: 991px) {
    .kunstwerk__slide__img__wrapper {
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 767px) {
    .kunstwerk__slide {
        margin: 0;
    }

    .kunstwerk__slide__content {
        padding: 45px 35px 35px;
    }

    .kunstwerk__slide__title {
        font-size: 19px;
    }

    .kunstwerk__slide__subtitle {
        font-size: 19px;
    }

    .archive__kunstwerken__arrow {
        height: 40px;
        width: 40px;
    }

    .archive__kunstwerken__arrow--prev,
    .archive__kunstwerken__arrow--next {
        display: none !important;
    }

    .archive__kunstwerken__arrow--prev--responsive,
    .archive__kunstwerken__arrow--next--responsive {
        display: block;
    }

    .kunstwerken__intro {
        margin-bottom: 25px;
        margin-top: 25px;
        padding-left: 30px;
        padding-right: 30px;
    }

    .kunstwerk__slide__summary {
        margin-top: 15px;
    }

    .kunstwerk__slide__img__wrapper {
        margin-bottom: 23px;
    }
}
