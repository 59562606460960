.front__layer {
    padding: 160px 0;

    &--activities {
        .front__layer__left {
            width: 50%;
            padding-right: 160px;
            padding-top: 15px;
            padding-bottom: 15px;
        }

        .front__layer__right {
            width: 50%;

            .front__layer__content {
                padding-left: 0;
            }
        }

        .front__layer__content {
            max-width: 500px;
        }
    }

    &--news {
        .front__layer__left {
            width: calc(50% - 160px);
        }

        .front__layer__right {
            width: calc(50% + 160px);
        }

        .front__layer__content {
            padding-right: 120px;
        }

        .front__layer__img {
            padding-right: 180px;
        }
    }
}

.front__layer__item {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    color: #fff;
    line-height: 1.8;
    padding: 0 10px;
    border: 2px dotted transparent;

    &:focus {
        @extend .focus;

        border: 0.2rem dotted #fff;
    }
}

.slick-dots li {
    width: 16px;
    height: 16px;
    padding: 0;
    margin: 0 5px;

    button {
        padding: 0;
        margin: 0;
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:focus {
            @extend .focus;

            outline-color: #fff;
            outline-offset: 2px;
        }
    }
}

.front__layer__title {
    h3 {
        font-size: 42px;
        font-weight: 700;
        color: #fff;
    }
}

.front__layer__tag {
    display: block;
    font-size: 21px;
    font-weight: 400;
}

.front__layer__content {
    .btn {
        margin-top: 40px;
        background-color: rgba(0, 0, 0, 0.2);

        &:focus {
            @extend .focus;

            outline-color: #fff;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0.5);
        }
    }
}

.front__layer__text {
    line-height: 1.7;
}

.front__layer__img {
    img {
        width: 100%;
        box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.3);
    }
}

.front__activities__container {
    position: relative;
}

.activities__slider {
    .slick-track {
        display: flex;
    }
}

.activities__slider__arrow {
    position: absolute;
    z-index: 1;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    // top calculated by javascript

    &--prev {
        left: -110px;
    }

    &--next {
        right: -80px;
    }

    svg {
        height: 40px;
        width: 20px;
        color: #fff;
        fill: #fff;
    }
}

.front__exhibition {
    position: relative;
    padding: 0;
    width: 100%;
    left: 0;
    margin-top: -250px;
}

.front__exhibition__inner {
    overflow: hidden;
}

.front__exhibition__container {
    position: relative;
}

.front__exhibition__tag {
    position: absolute;
    top: -10px;
    left: -140px;
    transform: rotate(270deg) translate(-50%, -50%);
    font-size: 23px;
    font-weight: 700;
    color: #fff;
}

.exhibition__slider__arrow {
    position: absolute;
    z-index: 1;
    top: calc(50% - 20px);
    cursor: pointer;

    &--prev {
        left: -80px;
    }

    &--next {
        right: -80px;
    }

    svg {
        height: 40px;
        width: 20px;
        color: #fff;
        fill: #fff;
    }
}

.exhibition__slider {
    margin-left: -10px;
    margin-right: -10px;
    width: calc(100% + 20px);

    .block__item {
        padding: 10px;
    }
}
