.hidden__form {
    display: none;
    margin: 0 20px;
    max-width: 768px;

    .gfield textarea {
        height: 150px;
    }
}

.hidden__label {
    label {
        display: none;
    }
}

.gfield {
    margin-bottom: 35px;

    input,
    select,
    textarea {
        background-color: #f0f0f0;
        box-shadow: 0 0 0 transparent;
        border-radius: $global-radius;
        height: 53px;
        border: 1px solid transparent;
        padding: 10px 25px;
        color: $body-font-color;
        width: 100%;
        font-size: 19px;

        &:focus {
            @extend .focus;

            border-radius: $global-radius;
        }

        &::placeholder {
            color: #8890aa;
        }
    }

    textarea {
        height: auto;
    }
}

.gsection {
    margin-bottom: 20px;
}

.gsection_title {
    font-size: 20px;
    margin-bottom: 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #e4e4e4;
}

.gfield_description {
    font-size: 14px;
    margin-top: 10px;
}

.gform_description {
    margin-bottom: 35px;
    display: block;
    max-width: 528px;
}

.gfield_label {
    font-weight: 700;
    margin-bottom: 10px;
}

.gfield_checkbox {
    padding: 0;
    margin: 0;
    list-style: none;

    > li {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        label {
            margin: 0;
        }

        input {
            width: auto;
            margin-right: 12px;
            height: auto;
        }
    }
}

.clear-multi {
    display: flex;
    margin: 0 -10px;

    > * {
        padding: 0 10px;
    }
}

.ginput_container_list {
    table {
        width: 100%;
    }

    tbody {
        display: flex;
        flex-direction: column;

        > tr {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;

            .gfield_list_cell {
                width: 100%;
            }

            .gfield_list_icons {
                display: flex;
                align-items: center;
                justify-content: center;

                > a {
                    margin: 0 3px;
                }
            }
        }
    }
}

.gfield_time_ampm {
    min-width: 85px;
    font-size: 14px;
}

.ginput_container_name {
    display: flex;
    margin: 0 -15px;

    > span {
        padding: 0 15px;
        flex: 0 0 50%;
        max-width: 50%;
    }

    label {
        display: block;
        order: 0;
    }
}

.ginput_container_post_image {
    input {
        padding: 4px;
    }
}

.ginput_container_fileupload {
    > input {
        padding: 4px;
    }
}

.ginput_container_address {
    > span {
        margin-bottom: 15px;
        display: block;

        > label {
            display: block;
            margin-top: 4px;
        }
    }
}

.gfield_contains_required {
    > .gfield_label {
        .gfield_required {
            display: none;
        }
    }
}

.ginput_container_consent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: -15px;

    a {
        text-decoration: underline;
        color: #138e9d;
    }

    > input {
        width: auto;
        height: 23px;
        margin-right: 15px;
    }

    > label {
        margin-bottom: 0;
    }
}

.gfield_radio {
    padding: 0;
    margin: 0;
    list-style: none;

    > li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 24px;

        label {
            margin: 0;
        }

        input {
            width: auto;
            margin-right: 12px;
            height: auto;
        }
    }
}

.ginput_container_multiselect {
    select {
        height: auto;
    }
}

.gfield_required {
    margin-left: 5px;
}

.gform_body {
    padding-left: 10px;
    padding-right: 10px;
}

.gform_fields {
    padding: 0;
    margin: 0 -15px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    > li {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 7.5px;

        &.full {
            flex: auto;
            width: 100%;
            max-width: 100%;
        }

        &.hidden__field {
            width: 0;
            height: 0;
            overflow: hidden;
            margin: 0;
        }
    }
}

.grecaptcha-badge {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
}

.validation_error {
    color: #ef5350;
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 15px;
}

.validation_message {
    position: relative;
    display: flex;
    color: #ef5350;
    font-size: 12px;
    font-weight: 500;
    margin: 5px 0 0;
    align-items: center;

    &::after {
        content: '';
        position: relative;
        margin: 0 0 0 auto;
        width: 14px;
        height: 12px;
        background-image: url('../../svg/exclamation-triangle-solid-red.svg');
        background-size: 14px 12px;
    }
}

.gform_button {
    margin: 0;
    background-color: $secondarycolor;

    &:hover,
    &:active,
    &:focus {
        background-color: lighten($secondarycolor, 5) !important;
    }
}

.gform_ajax_spinner {
    margin-left: 15px;
}

#ui-datepicker-div {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: (0 3px 5px 0 rgba(50, 50, 50, 0.75));
    display: none;
    padding: 20px;
    width: 300px;

    .ui-icon {
        color: transparent;
        cursor: pointer;
        font-size: 0;
    }

    .ui-icon::before {
        font-size: 18px;
    }

    .ui-datepicker-prev {
        float: left;
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .ui-icon::before {
            content: '';
            background-image: url('../../svg/angle-left-regular.svg');
            float: left;
            background-size: 9px;
            width: 10px;
            height: 19px;
            background-repeat: no-repeat;
            position: relative;
        }

        &.ui-state-disabled {
            display: none;
        }
    }

    .ui-datepicker-next {
        float: right;
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .ui-icon::before {
            content: '';
            background-image: url('../../svg/angle-right-regular.svg');
            float: right;
            background-size: 9px;
            width: 10px;
            height: 19px;
            background-repeat: no-repeat;
            position: relative;
        }

        &.ui-state-disabled {
            display: none;
        }
    }

    .ui-datepicker-header {
        display: block;
        float: left;
        width: 100%;
        margin-top: 12px;
    }

    .ui-datepicker-title {
        select {
            float: left;
            width: 70%;
        }

        .ui-datepicker-month {
            margin: 0 5% 6px;
            border-radius: 4px;
            padding: 3px;
            border: 1px solid #cfcfcf;
        }

        .ui-datepicker-year {
            margin: 0 15% 6px;
            border-radius: 4px;
            padding: 3px;
            border: 1px solid #cfcfcf;
        }
    }

    .ui-datepicker-today {
        background-color: #e6eef1;
        border-radius: 4px;

        a {
            color: #2a4982;
        }
    }

    table {
        width: 100%;

        td,
        th {
            text-align: center;
        }

        td {
            a {
                display: block;
                padding: 5px;
                border-radius: 4px;

                &:hover {
                    background-color: $primarycolor;
                    color: #fff;
                    text-decoration: none;
                }

                &.ui-state-active {
                    background-color: $primarycolor;
                    color: #fff;
                    text-decoration: none;
                }
            }
        }
    }
}

.gfield_checkbox li {
    &:not(:last-child) {
        margin-bottom: 5px;
    }

    input[type=checkbox] {
        opacity: 0;
        position: absolute;

        & + label {
            position: relative;
            overflow: hidden;
            padding-left: 30px;
            cursor: pointer;
            display: inline-block;

            &::before,
            &::after {
                content: '';
                position: absolute;
                left: 0;
                z-index: 1;
                transition: 0.2s;
            }
        }

        &:not(:checked) {
            & + label {
                &::before {
                    top: 3px;
                    left: 1px;
                    width: 7px;
                    height: 13px;
                    border: 3px solid transparent;
                    opacity: 0;
                    visibility: hidden;
                    transform: rotateZ(37deg);
                    transform-origin: 100% 100%;
                    transition: all 0.3s ease;
                }

                &::after {
                    width: 18px;
                    height: 18px;
                    border: 1px solid $primarycolor;
                    top: 2px;
                }
            }
        }

        &:checked {
            & + label {
                &::before {
                    top: 3px;
                    left: 1px;
                    width: 7px;
                    height: 13px;
                    margin-top: 0;
                    border-top: 2px solid transparent;
                    border-left: 2px solid transparent;
                    border-right: 2px solid $secondarycolor;
                    border-bottom: 2px solid $secondarycolor;
                    opacity: 1;
                    visibility: visibilty;
                    transform: rotateZ(37deg);
                    transform-origin: 100% 100%;
                }

                &::after {
                    top: 2px;
                    width: 18px;
                    height: 18px;
                    border: 1px solid $primarycolor;
                    background-color: transparent;
                    z-index: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .hidden__form {
        padding: 25px;
    }

    .gfield {
        margin-bottom: 20px;

        input,
        select,
        textarea {
            padding: 6px 15px;
        }
    }

    .gform_fields > li {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .gfield_label {
        margin-bottom: 5px;
    }
}
