.block--news {
    display: block;
    margin-bottom: 10px;
    overflow: hidden;

    .single__date {
        font-size: 16px;
        color: $secondarycolor;

        svg {
            color: $secondarycolor;
        }
    }
}

.news__block__img {
    position: relative;
    display: block;
    width: 100%;

    img {
        width: 100%;
    }
}

.block__content__news {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 35px 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.16);
    color: #fff;

    &:hover {
        .block__text--news {
            height: auto;
            opacity: 1;
            visibility: visible;
        }
    }
}

.news__block__title {
    position: relative;
    z-index: 2;
    display: block;
    font-size: 21px;
    font-weight: 700;
    line-height: 1.4;
}

.block__text--news {
    position: relative;
    z-index: 2;
    display: block;
    height: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
}

.sf-field-category {
    ul {
        margin: 0 0 40px;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        li {
            padding: 15px 20px 13px !important;
            background-color: #f9fafc;
            line-height: initial;
            margin-bottom: 10px;

            &:not(:last-child) {
                margin-right: 10px;
            }

            input[type=checkbox] {
                opacity: 0;
                position: absolute;

                & + label {
                    position: relative;
                    padding-left: 30px;
                    cursor: pointer;
                    display: inline-block;

                    &::before,
                    &::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        z-index: 1;
                        transition: 0.2s;
                    }
                }

                &:not(:checked) {
                    & + label {
                        &::before {
                            top: 1px;
                            left: 1px;
                            width: 7px;
                            height: 13px;
                            border: 3px solid transparent;
                            opacity: 0;
                            transform: rotateZ(37deg);
                            transform-origin: 100% 100%;
                            transition: all 0.3s ease;
                        }

                        &::after {
                            width: 18px;
                            height: 18px;
                            border: 1px solid $primarycolor;
                            top: 0;
                        }
                    }
                }

                &:checked {
                    & + label {
                        &::before {
                            top: 1px;
                            left: 1px;
                            width: 7px;
                            height: 13px;
                            margin-top: 0;
                            border-top: 2px solid transparent;
                            border-left: 2px solid transparent;
                            border-right: 2px solid $secondarycolor;
                            border-bottom: 2px solid $secondarycolor;
                            opacity: 1;
                            visibility: visibilty;
                            transform: rotateZ(37deg);
                            transform-origin: 100% 100%;
                        }

                        &::after {
                            top: 0;
                            width: 18px;
                            height: 18px;
                            border: 1px solid $primarycolor;
                            background-color: transparent;
                            z-index: 0;
                        }
                    }
                }
            }
        }
    }
}

.row__filter {
    margin-left: -5px;
    margin-right: -5px;

    > [class*=col-] {
        padding-left: 5px;
        padding-right: 5px;
    }

    .block__img {
        display: block;
    }

    img {
        display: block;
        width: 100%;
        height: auto;
    }
}

.single__img__nieuws {
    position: relative;
    display: block;
    width: 100%;
    max-width: 800px;
    margin-bottom: 25px;
    overflow: hidden;

    h1 {
        position: absolute;
        bottom: 30px;
        left: 0;
        color: #fff;
        padding: 0 30px;
        width: 100%;
        text-shadow: 0 0 12px rgba(0, 0, 0, 0.36);
        margin-bottom: 0;
    }

    img {
        width: 100%;
    }
}

.single__date {
    color: $secondarycolor;

    svg {
        color: $secondarycolor;
        fill: $secondarycolor;
    }
}

@media screen and (max-width: 1199px) {
    .block__content__news {
        padding: 25px 30px;
    }
}

@media screen and (max-width: 991px) {

}

@media screen and (max-width: 767px) {
    .block--news {
        .block__title {
            font-size: 18px !important;
        }
    }

    .block__content__news {
        padding: 15px 20px;
    }

    .news__block {
        .single__date {
            font-size: 15px;
        }
    }

    .news__block__content {
        padding: 20px 25px;
    }

    .news__block__title {
        font-size: 18px;
    }

    .single__img__nieuws {
        h1 {
            bottom: 20px;
        }
    }
}

@media screen and (max-width: 575px) {

}
