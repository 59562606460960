/* Pagination numbered */
.pagination__numbers {
    border-top: 1px solid #eee;
    width: 100%;
    padding-top: 50px;
    display: flex;
    justify-content: center;

    .page-numbers {
        width: 30px;
        height: 30px;
        border-radius: 4px;
        background: $primarycolor;
        display: flex;
        float: left;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 15px;
        margin: 0 3px;
        text-decoration: none;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &:hover,
        &:focus {
            background-color: darken($primarycolor, 5);
            text-decoration: none;
        }

        &.current {
            background-color: darken($primarycolor, 5);
        }
    }
}

.fancybox-close-small {
    &:focus {
        @extend .focus;
    }
}

.cookie__inner__btn {
    &:focus {
        @extend .focus;
    }
}

.single__date {
    display: block;
    margin-bottom: 15px;
    font-weight: 600;

    svg {
        position: relative;
        top: -2px;
        width: 14px;
        height: 16px;
        margin-right: 8px;
        color: $body-font-color;
        fill: $body-font-color;
    }
}

.single__text {
    margin-bottom: 28px;

    h4 {
        font-size: 22px;
        margin-bottom: 3px;
    }

    p {
        margin-bottom: 40px;
    }
}

.other__page {
    position: relative;
    height: 500px;
    background-color: $bluecolor;
    z-index: 2;

    div {
        &.container-fluid,
        &.row,
        &.col,
        &.other__page__inner {
            height: 100%;
        }
    }
}

.other__page__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.58;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.other__page__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    flex-direction: column;

    .btn {
        outline-color: #fff;
    }

    a {
        margin-top: 15px;
    }
}

.other__page__title {
    text-shadow: 0 0 15px #000;
    color: #fff;
    font-weight: 700;
    font-size: 37px;
    line-height: 1.2;
}

.other__page__subtitle {
    margin-bottom: 9px;
    text-shadow: 0 0 15px #000;
    font-size: 21px;
}

.archive__content {
    padding: 80px 0 40px;
    text-align: center;
}

.layer--arrangements {
    .archive__content {
        padding-bottom: 0;
    }
}

.archive__content--kunstwerken {
    padding-bottom: 140px;
}

.no__banner {
    padding-top: 200px;
}

.container-fluid--archive {
    padding-left: 22px;
    padding-right: 22px;
}

.row--archive {
    margin-left: -22px;
    margin-right: -22px;
    justify-content: center;

    > .col-6 {
        padding-left: 22px;
        padding-right: 22px;
    }
}

.single__main {
    table {
        border: 1px solid #b9c7ff;
        vertical-align: middle;

        tr {
            td {
                padding: 8px 25px 2px;
                vertical-align: middle;
                border: 1px solid #b9c7ff;
            }
        }
    }
}

.archive__item {
    margin-bottom: 44px;

    &--passed {
        img {
            filter: grayscale(100%);
        }

        &:hover {
            img {
                filter: grayscale(0);
            }
        }
    }

    &--big {
        .block__content {
            text-align: left;
        }
    }
}

.overview__link {
    display: flex;
    align-items: center;
    font-weight: 700;

    &:hover {
        svg {
            right: 3px;
        }
    }

    svg {
        color: currentColor;
        position: relative;
        width: 10px;
        height: 34px;
        top: -1px;
        right: 0;
        margin-right: 10px;
        transition: right 0.2s ease-in-out;
    }
}

.template-tickets {
    h1 {
        text-align: center;
    }
}

.loadmore {
    display: block;
    text-align: center;
}

.btn-loadmore {
    display: inline-block;
    margin: 40px auto 0;
    cursor: pointer;
}

@media screen and (max-width: 767px) {
    .single__main {
        table {
            tr {
                td {
                    padding: 10px;
                }
            }
        }
    }
}
