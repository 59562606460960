@media screen and (max-width: 575px) {
    .wysiwyg_editor_blue {
        padding: 30px 20px;
    }

    .front__exhibition {
        margin-top: -140px;
    }

    .nav-primary {
        .menu__tickets__button {
            flex: 0 0 100%;
            max-width: calc(100% - 40px);
            top: 200px;
        }
    }

    @media (orientation: landscape) {
        .banner__content h1 {
            margin-bottom: 0;
        }

        .front__exhibition {
            margin-top: -60px;
        }
    }
}
