.layer--arrangements {
    text-align: center;
}

.archive__arrangements {
    margin-top: 40px;
}

.container-fluid--arrangements {
    margin-top: 80px;
    padding-left: 10px;
    padding-right: 10px;
}

.row--arrangements {
    margin-left: -10px;
    margin-right: -10px;

    .col-12 {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.arrangement__group__title {
    display: block;
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: 700;
    color: $primarycolor;
}

.arrangements__intro {
    p {
        max-width: 600px;
        margin: 0 auto;
    }
}

.arrangement__item {
    display: flex;
    align-content: center;
    justify-content: center;
    background-color: #f9fafc;
    margin-top: 20px;
}

.menu__image {
    margin-top: 40px;
    margin-bottom: 20px;
    max-width: 800px;
}

.arrangement__item__img {
    background-size: cover;
    background-position: center center;
    width: 135px;
}

.arrangement__item__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $primarycolor;
    padding: 25px 30px;
    flex: 1;
    text-align: left;

    span {
        display: flex;
        width: 100%;
        font-size: 16px;
        font-style: italic;
        line-height: 20px;

        &:first-child {
            margin-bottom: 10px !important;
        }

        &:not(:last-child) {
            margin-bottom: 3px;
        }
    }

    svg {
        position: relative;
        top: 1px;
        color: #6382ff;
        fill: #6382ff;
        margin-right: 5px;

        &.fa-map-marker-alt {
            height: 14px;
            width: 11px;
        }

        &.fa-euro-sign {
            height: 14px;
            width: 9px;
        }
    }
}

.arrangement__item__title {
    font-size: 17px;
    font-weight: 700;
    color: $primarycolor;
    font-style: normal !important;
    margin-bottom: 10px;
}
