.header .nav li {
    @extend .nav-item;
}

.header .nav a {
    @extend .nav-link;
}

.header {
    position: relative;
    z-index: 950;
}

.topbar {
    position: relative;
    z-index: 3;
    background-color: $bluecolor;
    color: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.topbar__inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
}

.top__languagues__switch {
    display: none;
    text-transform: uppercase;
}

.language__item {
    text-transform: uppercase;
}

.top__languages {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 12px;

    a {
        color: #e3e3f2;
        margin-right: 10px;

        &.active {
            color: #fff;
        }

        &:hover {
            color: #fff;
            outline-offset: 2px;
        }

        &:focus {
            outline-color: #fff;
        }
    }

    .language__item--active {
        color: #fff;
    }
}

.top__languagues__inner {
    display: flex;
}

.top__openinghours {
    font-size: 16px;
    font-weight: 600;

    a {
        color: #fff;

        &:hover {
            color: #fff;
        }
    }
}

.header__logo {
    position: absolute;
    left: 20px;
    top: 50px;
    z-index: 2;

    &:focus {
        outline-offset: 10px;
    }

    svg {
        width: 234px;
        height: 64px;
    }

    &.white {
        svg {
            fill: #fff;

            rect,
            path {
                fill: #fff;
            }
        }
    }
}

.menu__open {
    .header__logo {
        @extend .header__logo, .white;
    }

    .header__bottom__btns__inner {
        .btn {
            &:focus {
                outline-color: #fff;
            }
        }
    }
}

.header__bottom {
    position: relative;
    z-index: 2;

    > .container-fluid {
        > .row {
            > .col-12 {
                &.white {
                    &::before {
                        position: absolute;
                        left: -280px;
                        top: -330px;
                        content: '';
                        width: 812px;
                        height: 812px;
                        max-width: 100%;
                        background: radial-gradient(circle, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 35%);
                    }
                }
            }
        }
    }
}

body {
    &.menu__open {
        overflow: hidden;
        padding-right: 17px;

        .header__bottom {
            > .container-fluid {
                > .row {
                    > .col-12 {
                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.header__bottom__btns {
    position: fixed;
    right: 0;
    top: 92px;
    width: 100%;
    z-index: 1;
    pointer-events: none;
}

.menu__open .header__bottom__btns {
    padding-right: 17px;
}

.header__bottom__btns__inner {
    display: flex;
    justify-content: flex-end;

    .btn {
        pointer-events: initial;

        &:focus {
            @extend .focus;

            .btn__open,
            .btn__closed,
            .btn--tickets__name {
                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    border-bottom: 1px solid $primarycolor;
                    width: 100%;
                }
            }
        }
    }
}

.btn--tickets {
    padding-left: 23px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.21);

    svg {
        width: 22px;
        height: 15px;
        transform: rotate(-22deg);
        fill: $primarycolor;
        margin-right: 20px;
    }
}

.contact {
    .header__bottom {
        > .container-fluid {
            > .row {
                > .col-12 {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}
