.nav-primary {
    position: fixed;
    z-index: 900;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: #29497a;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    pointer-events: none;

    &.menu__open {
        opacity: 1;
        pointer-events: all;
        overflow: auto;
    }

    a {
        color: #fff;
        display: flex !important;
        align-items: center;
        justify-content: space-between;
    }

    ul,
    li {
        list-style: none;
        list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
        position: relative;
        padding: 0;
        margin: 0;
    }

    .tickets__button__wrap {
        position: relative;
        bottom: 50px;
        top: 260px;
        display: none;
        justify-content: center;
    }

    .menu__tickets__button {
        font-size: 18px;
        max-width: 280px;
        color: $primarycolor;
        justify-content: center;

        &:hover {
            color: $secondarycolor;
        }
    }

    .nav {
        display: flex;

        > li {
            height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 0 0 20%;
            max-width: 20%;
            padding: 0 35px;

            /* stylelint-disable */
            &:focus-visible {
                @extend .focus;

                outline-offset: 0;
                outline-color: #fff;
            }
            /* stylelint-enable */

            &.open {
                background-image: linear-gradient(to top, #213a62 0%, #29497a 100%);

                .sub-menu {
                    //height: 100%;
                    //visibility: visible;
                    pointer-events: all;
                    opacity: 1;
                    top: calc(60% - 85px);
                }

                > a {
                    margin-top: -130px;
                    transition: all 300ms ease-in-out;
                }
            }

            > a {
                color: #fff;
                width: 100vw;
                text-transform: uppercase;
                font-size: 34px;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                font-weight: 700;
                text-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
                line-height: 1.3;

                &:hover {
                    color: #fff;
                }

                &:focus {
                    @extend .focus;

                    transition: margin 300ms ease-in-out;
                    outline-color: #fff;
                }

                span {
                    display: block;
                    font-size: 29px;
                    line-height: 1.2;
                    font-weight: 400;
                    text-transform: none;
                    margin-top: -5px;
                    margin-bottom: 0;
                    color: #b9c7ff;
                    transition: margin-bottom 325ms ease-in-out;
                }

                svg {
                    display: none;
                }
            }
        }

        .sub-menu {
            position: absolute;
            opacity: 0;
            //visibility: hidden;
            //height: 0;
            pointer-events: none;
            border-top: 4px solid rgba(#fff, 0.05);
            padding-top: 40px;
            top: 60%;
            transition: all 325ms ease-in;

            &.sub-menu1 {
                left: 35px;
                right: 35px;

                > li {
                    a {
                        display: flex;
                        margin-left: 0;
                        margin-bottom: 10px;
                        transition: margin-left 200ms ease-in-out;

                        &:focus {
                            @extend .focus;

                            outline-color: #fff;
                        }

                        &:hover {
                            margin-left: 8px;
                        }
                    }

                    .fa-angle-right {
                        width: 6px;
                        height: 16px;
                        color: #fff;
                    }
                }
            }
        }
    }
}

.btn--tickets__name {
    position: relative;
}

// Menu bar icon
.menu__icon {
    display: flex;
    align-items: center;
    padding-left: 60px;
    cursor: pointer;
    margin-right: 20px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.21);
    transition: all 0.3s ease;

    &.menu--fixed {
        top: 25px;
    }

    .btn__open {
        display: block;
        position: relative;
    }

    .btn__closed {
        display: none;
        position: relative;
    }

    span {
        display: block;
        position: absolute;
        height: 3px;
        width: 17px;
        background: $primarycolor;
        opacity: 1;
        left: 24px;
        transform: rotate(0deg);
        transition: 250ms ease-in-out;

        &:nth-child(1) {
            top: 20px;
        }

        &:nth-child(2) {
            top: 27px;
        }

        &:nth-child(3) {
            top: 27px;
        }

        &:nth-child(4) {
            top: 34px;
        }
    }

    &.open {
        .btn__open {
            display: none;
        }

        .btn__closed {
            display: block;
        }

        span {
            &:nth-child(1) {
                top: 18px;
                width: 0;
                left: 24px;
            }

            &:nth-child(2) {
                transform: rotate(45deg);
            }

            &:nth-child(3) {
                transform: rotate(-45deg);
            }

            &:nth-child(4) {
                top: 18px;
                width: 0;
                left: 24px;
            }
        }
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    .nav-primary {
        .nav {
            .sub-menu {
                &.sub-menu1 {
                    > li {
                        line-height: 1;

                        a {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

@supports (-ms-ime-align:auto) {
    .nav-primary {
        .nav {
            .sub-menu {
                &.sub-menu1 {
                    > li {
                        line-height: 1;

                        a {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1588px) {
    .nav-primary {
        .nav {
            > li {
                padding: 0 17px;

                > a {
                    font-size: 28px;

                    span {
                        font-size: 24px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1199px) {
    .nav-primary {
        .nav {
            > li {
                > a {
                    font-size: 22px;

                    span {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .nav-primary {
        .nav {
            top: 210px;
            margin-left: -20px;
            margin-right: -20px;

            > li {
                flex: 0 0 100%;
                max-width: 100%;
                height: auto;
                justify-content: flex-start;
                align-items: center;
                position: relative;
                padding: 25px 43px;

                .sub-menu {
                    position: relative;
                    opacity: 1;
                    pointer-events: none;
                    padding-top: 10px !important;
                    top: 0 !important;
                    height: auto;
                    display: none;

                    &.sub-menu1 {
                        left: 0;
                        right: auto;
                    }
                }

                &:hover {
                    background-image: none;

                    > a {
                        span {
                            margin-bottom: 0;
                        }
                    }
                }

                &.menu-item-has-children {
                    flex-direction: column;
                    align-items: flex-start;
                    cursor: pointer;

                    &.submenu__open,
                    &.open {
                        //background-image: linear-gradient(to top, #213a62 0%, #29497a 100%);

                        .sub-menu {
                            display: block;
                        }

                        &::after {
                            transform: rotate(180deg);
                            background-image: url("../../svg/angle-down-light--grey-full.svg");
                        }

                        > a {
                            &::before {
                                width: 100%;
                                background-image: none;
                                background-color: darken(#25426f, 10);
                            }
                        }
                    }

                    &::after {
                        content: '';
                        background-image: url("../../svg/angle-down-light--grey.svg");
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: 50%;
                        width: 25px;
                        height: 28px;
                        position: absolute;
                        top: 38px;
                        right: 50px;
                        transition: all 325ms ease-in-out;
                    }
                }

                &:last-child {
                    > a {
                        &::after {
                            content: '';
                            background-image: linear-gradient(to top, #213a62 0%, #b9c7ff 100%);
                            opacity: 0.1;
                            width: 43px;
                            height: 3px;
                            position: absolute;
                            left: 0;
                            bottom: 0;
                        }
                    }
                }

                > a {
                    height: auto;
                    width: 100%;
                    font-size: 24px;

                    &::before {
                        content: '';
                        background-image: linear-gradient(to top, #213a62 0%, #b9c7ff 100%);
                        opacity: 0.1;
                        width: 43px;
                        height: 3px;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }

                    span {
                        font-size: 19px;
                    }
                }
            }

            .sub-menu {
                border-top: none;

                &.sub-menu1 {
                    left: 43px;
                    right: 43px;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .menu__icon {
        padding-left: 55px !important;

        span {
            &:nth-child(1) {
                top: 16px;
            }

            &:nth-child(2) {
                top: 23px;
            }

            &:nth-child(3) {
                top: 23px;
            }

            &:nth-child(4) {
                top: 30px;
            }
        }
    }

    .nav-primary {
        height: 100%;
        width: 100%;

        .nav {
            overflow: auto;
            height: 100%;
            top: 120px;
            padding-bottom: 87px;

            > li {
                padding: 15px 43px;

                > a {
                    font-size: 18px;
                    line-height: 1.8;

                    .nav__item__sub-label {
                        font-size: 16px;
                    }
                }

                .sub-menu {
                    &.sub-menu1 {
                        li {
                            > a {
                                font-size: 17px;
                            }
                        }
                    }
                }

                &.menu-item-has-children {
                    &.submenu__open,
                    &.open {
                        .sub-menu {
                            padding-top: 30px;
                        }
                    }

                    &::after {
                        width: 18px;
                        height: 10px;
                        right: 25px;
                    }
                }
            }
        }

        .tickets__button__wrap {
            position: fixed;
            top: auto;
            bottom: 0;
            left: 0;
            display: flex;
            width: 100%;
            padding: 20px 0;
            background-color: $bluecolor;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

            .menu__tickets__button {
                top: 0;
                position: relative;
            }
        }
    }
}

@media screen and (max-width: 575px) {

}
