@media screen and (max-width: 767px) {
    body {
        font-size: 15px;
    }

    h1 {
        font-size: 22px;
    }

    h2 {
        font-size: 20px;
    }

    h3 {
        font-size: 18px;
    }

    h4 {
        font-size: 16px;
    }

    h1,
    h2 {
        margin-bottom: 15px;
    }

    .btn {
        font-size: 18px;
        padding: 12px 20px;
    }

    .layer {
        padding: 50px 0;

        &--exhibition {
            padding: 20px 0 30px;
        }

        &.no__banner {
            padding-top: 110px;
        }
    }

    .block__item {
        &:hover {
            .block__content {
                bottom: -30px;
            }
        }
    }

    .block__content {
        bottom: -30px;
        padding: 0 7px;
    }

    .block__tag {
        font-size: 13px;
    }

    .front__exhibition__tag {
        top: -2px;
        left: -110px;
        font-size: 17px;
    }

    .front__exhibition__inner {
        padding: 0 0 0 60px;
    }

    .exhibition__slider {
        margin-left: -20px;
        margin-right: -20px;
        width: calc(100% + 40px);

        .slick-list {
            padding: 0 25% 0 0;
        }

        .block__item {
            padding: 10px 6px;
        }

        .block__img {
            box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
        }
    }

    .exhibition__slider__arrow {
        svg {
            position: relative;
            top: 5px;
            height: 30px;
            width: 15px;
        }
    }

    .exhibition__slider__arrow--next {
        display: none !important;
    }

    .archive__item--big {
        .block__tag {
            font-size: 14px;
        }

        .block__title {
            font-size: 17px;
            margin-bottom: 10px;
        }

        .block__content {
            padding: 10px;
            bottom: -40px;
        }

        &:hover {
            .block__content {
                bottom: -40px;
            }
        }
    }

    .block__title {
        font-size: 15px !important;
    }

    .block__btn {
        font-size: 13px !important;
        padding: 5px 12px !important;

        span {
            display: none;
        }
    }

    .single__text {
        h4 {
            font-size: 15px;
        }

        p {
            margin-bottom: 30px;
        }
    }

    .sidebar__img {
        display: none;

        &.sidebar__img__kunstwerk {
            display: block;
        }
    }

    .sidebar__nav {
        margin-top: 0;
        padding: 35px;
    }

    .sidebar__nav__title {
        font-size: 18px;
    }

    .sidebar__nav__link {
        svg {
            top: 5px;
            height: 16px;
            width: 8px;
        }
    }

    .opening__list--special .opening__right {
        min-width: 65px;
    }

    .layer--arrangements {
        padding-top: 50px;
        padding-bottom: 20px;
    }

    .layer--activities {
        padding-top: 20px;
    }

    .archive__content {
        padding: 20px 0 10px;
        text-align: left;

        &.no__banner {
            padding-top: 100px;
        }
    }

    .container-fluid--archive {
        padding-left: 20px;
        padding-right: 20px;
    }

    .row--archive {
        margin-left: -7px;
        margin-right: -7px;
        justify-content: flex-start !important;

        > .col-6 {
            padding-left: 7px;
            padding-right: 7px;
        }
    }

    .archive__item {
        margin-bottom: 14px;
    }

    .banner__content h1 {
        font-size: 18px;
        line-height: 1.4;
        margin-bottom: 90px;
    }

    .banner__content h1 span {
        font-size: 31px;
    }

    .header__bottom__btns__inner {
        .btn--tickets {
            display: none;
        }
    }

    .header__logo svg {
        fill: #fff;
        width: 176px;
        height: 48px;
    }

    .header__bottom > .container-fluid > .row > .col-12::before {
        width: 490px;
        height: 490px;
        left: -170px;
        top: -170px;
    }

    .topbar__inner {
        justify-content: flex-end;
    }

    .top__openinghours {
        font-size: 13px;
    }

    .header__logo {
        top: 25px;
    }

    .header__bottom__btns {
        position: absolute;
        top: 25px;
    }

    .top__languagues__inner {
        position: absolute;
        top: 40px;
        left: -13px;
        display: none;
        background-color: #fff;
        padding: 5px 13px 4px 13px;
        flex-direction: column;

        a {
            margin-right: 0;
            color: $body-font-color;
            padding-top: 3px;
            display: block;
        }

        .language__item--active {
            color: #092376;
        }
    }

    .top__languages {
        &:hover {
            .top__languagues__inner {
                display: block;
            }
        }
    }

    .top__languagues__switch {
        display: inline-flex;

        svg {
            width: 8px;
            height: 16px;
            margin-left: 5px;
        }
    }

    .banner__content .banner__title {
        display: block;
        font-size: 18px;
    }

    .banner__content .banner__title span {
        font-size: 28px;
    }

    .front__exhibition {
        margin-bottom: 30px;
        margin-top: -200px;

        .block__tag {
            font-size: 13px;
        }
    }

    .front__layer {
        padding: 45px 0 55px;

        &--activities {
            padding-top: 0;
        }
    }

    .front__layer__content {
        .btn {
            margin-top: 20px;
        }
    }

    .front__layer__title {
        h3 {
            font-size: 22px;
            margin-bottom: 15px;
        }
    }

    .front__layer__tag {
        font-size: 17px;
    }

    .front__layer__img {
        margin-bottom: 25px;
    }

    .slick-dots {
        bottom: -35px;
        text-align: left;
    }

    .footer__row {
        padding: 30px 0;
    }

    .newsletter__fields {
        input[type="email"] {
            flex: unset;
            margin-bottom: 8px;
        }
    }

    .visit {
        padding: 50px 0 40px;
    }

    .layer.single__content {
        padding-bottom: 80px;
    }

    .single-activiteiten {
        .visit {
            padding-top: 0;
        }
    }

    .visit__title {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .visit__block__inner {
        padding: 25px 30px;
    }

    .visit__block__content {
        margin-bottom: 20px;
    }

    .about-us {
        padding: 40px 0 50px;
    }

    .about-us__left,
    .about-us__right {
        text-align: left;
    }

    .about-us__left {
        img {
            max-width: 100%;
        }
    }

    .about-us__title {
        font-size: 22px;
        margin-bottom: 8px;
    }

    .usps_title {
        font-size: 20px;
    }

    .usps__list__item {
        font-size: 15px;

        svg {
            width: 16px;
            height: 11px;
            margin-right: 5px;
            margin-top: 5px;
        }
    }

    .about-us__subtitle {
        font-size: 17px;
        margin-bottom: 4px;
    }

    .other__page__title {
        font-size: 19px;
    }

    .other__page__subtitle {
        font-size: 19px;
    }

    .other__page {
        height: 240px;
    }

    .friends-page__content {
        h1 {
            margin-bottom: 20px;
        }
    }

    .friends {
        margin-top: 30px;
    }

    .list__pages {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            margin-bottom: 10px;

            a {
                font-size: 15px;

                &::before {
                    width: 10px;
                    height: 15px;
                }
            }
        }
    }

    .sidebar__title {
        margin-bottom: 10px;
        font-size: 20px;
    }

    .openinghours__block {
        display: block;
        margin-bottom: 0;

        &:not(:last-child) {
            margin-bottom: 30px;
        }

        &--special {
            margin: 20px 0 0 !important;

            .season__period {
                margin-bottom: 0;
            }
        }
    }

    .season__title {
        font-size: 17px;
        line-height: 1;
    }

    .season__period {
        font-size: 18px;
        margin-bottom: 5px;
    }

    .special__container {
        margin-top: 30px;
    }

    .contact__form__inner {
        padding: 30px;

        h3 {
            font-size: 20px;
        }
    }

    input,
    select,
    textarea {
        font-size: 15px;

        &::placeholder {
            font-size: 15px;
        }
    }

    .contact__main {
        margin-bottom: 20px;
    }

    .visit__block--contact {
        margin-top: 30px;

        .visit__block__title {
            font-size: 17px;
        }
    }

    .visit__block {
        background-color: transparent;
        border-right: 0;
        padding: 0;

        &:not(:last-child) {
            flex: 0 0 100%;
            max-width: 100%;
            border-right: 0;
            border-bottom: 4px solid rgba(#092376, 0.06);
        }

        &:nth-child(-n+1) {
            border-right: 0;
        }
    }

    .visit__link {
        svg {
            width: 6px;
            height: 16px;
            margin-left: 5px;
        }
    }

    .overview__link {
        svg {
            width: 6px;
            height: 16px;
            margin-right: 5px;
        }
    }

    .visit__block__item {
        font-size: 15px;
    }

    .container-fluid--arrangements {
        margin-top: 40px;
    }

    .archive__arrangements {
        margin-top: 20px;
    }

    .arrangement__item {
        flex-direction: column;
    }

    .arrangement__item__img {
        width: 100%;
        height: 150px;
    }

    .arrangement__group__title {
        font-size: 24px;
        margin-bottom: 0;
    }

    .about-us__right__inner {
        p {
            margin-bottom: 25px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .layer.single__content--bottom-padding {
        padding-bottom: 40px;
    }

    .footer__left__inner {
        padding-top: 0;
        padding-bottom: 25px;

        svg {
            width: 200px;
        }
    }

    .newsletter__title {
        font-size: 17px;
    }

    .newsletter__subtitle {
        font-size: 13px;
    }

    .menu__icon {
        position: fixed;
        margin-right: 0;
        top: 65px;
    }

    .archive__activities--date {
        margin-bottom: 40px;
    }

    .newsletter__heading__social {
        padding: 10px 0 0 0;

        a {
            width: 30px;
            height: 30px;

            &.instagram {
                margin-left: 6px;
            }

            svg {
                height: 22px;

                &.fa-instagram {
                    height: 20px;
                    width: 20px;
                }
            }
        }
    }

    .archive__content__geschiedenis {
        padding-bottom: 40px;
    }

    .friends__item:nth-child(-n+3) {
        border-top: none;
    }

    .arrangements__intro {
        text-align: start;

        p {
            max-width: unset;
        }
    }

    .kunstwerk__slide__summary {
        margin-top: 8px;
    }

    .kunstwerk__slide__readmore {
        margin-top: 10px;
    }

    .kunstwerk__slide__inner {
        padding: 0 20px;
    }

    .slider-nav-responsive {
        display: flex;
        margin: 30px 0;
    }

    .archive__kunstwerken__arrow--prev--responsive,
    .archive__kunstwerken__arrow--next--responsive {
        display: flex !important;
    }

    .archive__kunstwerken__arrow {
        width: 45px;
        height: 45px;

        svg {
            width: 13px;
            height: 20px;
        }
    }

    .friends__item__inner {
        padding: 25px;
    }

    .iframe__wrapper {
        margin-left: -10px;

        iframe {
            min-height: 960px !important;
        }
    }

    .home {
        .banner__content {
            top: 0;
        }
    }

    @media (orientation: landscape) {
        .banner__content h1 {
            margin-bottom: 30px;
        }

        .front__exhibition {
            margin-top: -70px;
        }
    }
}
